<template lang="pug">
div
  // 联系人卡片
  van-contact-card(:type='cardType', :name='currentContact.name', :tel='currentContact.tel', @click='showList = true')
  // 联系人列表
  //- van-popup(v-model='showList', position='bottom')
  van-contact-list(v-model='chosenContactId', :list='list', @add='onAdd', @edit='onEdit', @select='onSelect')
  // 联系人编辑
  van-popup(v-model='showEdit', position='bottom')
    van-contact-edit(:contact-info='editingContact', :is-edit='isEdit', @save='onSave', @delete='onDelete' show-set-default =true, set-default-label= '设为默认联系人' )

</template>

<script>
import { Sticky } from 'vant';

export default {
  components: {
    [Sticky.name]: Sticky
  },
  data() {
    return {
      chosenContactId: null,
      customer_id: 0,
      editingContact: {},
      showList: false,
      showEdit: false,
      isEdit: false,
      list: [
        {
          name: '张三',
          tel: '13000000000',
          id: 0,
          isDefault: false,
        },
      ],
    }
  },
  computed: {
    cardType() {
      return this.chosenContactId !== null ? 'edit' : 'add';
    },

    currentContact() {
      const id = this.chosenContactId;
      return id !== null ? this.list.filter((item) => item.id === id)[0] : {};
    },
  },
  methods: {
    async onLoad() {
      this.list = []
      try {
        let res = await this.$api.GetContact(this.customer_id)
        this.list = res.data.items.map((item) => {
         return {
            id: item.id,
            name: item.name,
            tel: item.mobile,
            isDefault: item.is_default  === 'FALSE' ? false : true 
          }
        })
        // console.log('获取我的客户',this.list)
      } catch (ex) {
          // console.log(`我的客户请求失败:${ex}`)
      }
    },
     // 添加联系人
    onAdd() {
      this.editingContact = { id: this.list.length };
      this.isEdit = false;
      this.showEdit = true;
      // console.log('onadd',this.editingContact)
    },

    // 编辑联系人
    onEdit(item) {
      this.isEdit = true;
      this.showEdit = true;
      this.editingContact = item;
      // console.log('edit',item)
    },

    // 选中联系人
    onSelect() {
      this.showList = false;
    },

    // 保存联系人
    async onSave(info) {
      this.showEdit = false;
      this.showList = false;
      let param = {
          name: info.name,
          mobile: info.tel,
          is_default: info.isDefault ? '1' : '0',
          cus_com_poi_ofc_cus_companys: this.customer_id
      }
      // put 
      if (this.isEdit) {
        param.id = info.id
        let data = JSON.stringify(param); 
        try {
          let res = await this.$api.PutContact(data)
          this.$toast('修改成功')
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
        } catch (error) {
          this.$toast('修改失败' + error)
        }
       
        this.list = this.list.map((item) =>
          item.id === info.id ? info : item
        );
      } else { // post 
        // console.log('post',param)
        try {
          let data = JSON.stringify(param); 
          let res = await this.$api.PostContact(data)
          this.$toast('添加成功',res)
          info.id = res.data.id
          this.list.push(info)
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
        } catch (error) {
          this.$toast('添加失败' + error)
        }
      }
      this.chosenContactId = info.id;
      // console.log('save',info)
    },

    // 删除联系人
    async onDelete(info) {
      this.showEdit = false
      try {
        let param = { id: info.id }
        let res = await this.$api.DeleteContact(param)
        this.list = this.list.filter((item) => item.id !== info.id);
        this.$toast('删除成功')
      } catch (error) {
        this.$toast('删除失败' + error)
        
      }
      if (this.chosenContactId === info.id) {
        this.chosenContactId = null;
      }
    },
  },
  async activated() {
    this.customer_id = (this.$route.query).id || 0
    // if(this.customer_id === 0) this.$toast('Error:请检查用户登录信息')
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      rightText: '新建联系人',
      rightArrow: true,
      onClickRight: ()=> {
        this.onAdd()
      }
    }),
    this.onLoad()
  }
}
</script>